.container {
  container-type: inline-size;
  container-name: my-accounts-container;
  display: flex;
  justify-content: center;
}

.myAccounts {
  width: 100%;
  max-width: 600px;
  margin: var(--space-2);
}

.safeList {
  padding: var(--space-3);
  margin-bottom: var(--space-3);
}

.safeList :last-child {
  margin-bottom: 0;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--space-3) 0;
  gap: var(--space-1);
}

.listItem {
  border: 1px solid var(--color-border-light);
  border-radius: var(--space-1);
  margin-bottom: 12px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  flex-wrap: wrap;
}

.currentListItem {
  border: 1px solid var(--color-secondary-light);
  border-left-width: 6px;
  background-color: var(--color-background-light) !important;
}

.currentListItem.multiListItem {
  border: 1px solid var(--color-border-light);
  background-color: none;
}

.listItem :global .MuiAccordion-root,
.listItem :global .MuiAccordion-root:hover > .MuiAccordionSummary-root {
  background-color: transparent;
}

.listItem :global .MuiAccordion-root.Mui-expanded {
  background-color: var(--color-background-paper);
}

.listItem.subItem {
  margin-bottom: 8px;
}

.subItem .borderLeft {
  top: 0;
  bottom: 0;
  position: absolute;
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
}
.subItem.currentListItem .borderLeft {
  border-left: 4px solid var(--color-secondary-light);
}

.listItem > :first-child {
  flex: 1;
  width: 90%;
}

.safeLink {
  display: grid;
  padding: var(--space-2) var(--space-1) var(--space-2) var(--space-2);
  grid-template-columns: 60px 3fr 3fr minmax(auto, 2fr);
  align-items: center;
}

.safeSubLink {
  display: grid;
  padding: var(--space-2) var(--space-1) var(--space-2) var(--space-2);
  grid-template-columns: 60px 3fr minmax(auto, 2fr);
  align-items: center;
}

.safeName,
.safeAddress {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listHeader {
  padding-bottom: var(--space-1);
  display: flex;
  justify-content: space-between;
}

.listTitle {
  margin: auto 0;
}

.card {
  margin: auto;
  padding: var(--space-3);
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.card :global .MuiCardHeader-root,
.card :global .MuiCardContent-root {
  padding: 0;
}

.cardHeader {
  text-align: center;
}

.infoIcon {
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  margin: 4px;
  color: var(--color-text-secondary);
}

.chip {
  border-radius: 4px;
  background-color: var(--color-warning-background);
  margin-top: 4px;
}

.pendingAccount {
  background-color: var(--color-info-light);
}

.pendingLoopIcon {
  color: var(--color-info-dark) !important;
}

.multiChains {
  display: flex;
  justify-content: flex-end;
}

.multiChains > span {
  margin-left: -5px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.chainIndicator {
  justify-content: flex-end;
}

@media (max-width: 899.95px) {
  .container {
    width: auto;
  }

  .safeLink {
    padding-right: 0;
  }
}

@media (max-width: 599.95px) {
  .safeLink {
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'a b d'
      'a c d';
  }

  .safeLink :nth-child(1) {
    grid-area: a;
  }
  .safeLink :nth-child(2) {
    grid-area: b;
  }
  .safeLink :nth-child(3) {
    grid-area: c;
    text-align: left;
  }
  .safeLink :nth-child(4) {
    grid-area: d;
  }

  .multiChains {
    justify-content: flex-start;
  }
}

@container my-accounts-container (max-width: 500px) {
  .myAccounts {
    margin: 0;
  }

  .header {
    padding: var(--space-3);
    border-bottom: 1px solid var(--color-border-light);
  }

  .safeList {
    border-radius: 0;
    margin-bottom: 0;
  }

  .title {
    font-size: 20px;
  }

  .card {
    border-top: 1px solid var(--color-border-light);
  }
}
